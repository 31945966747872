<template>
  <div>
    <div v-for="(zone, zk) in activeZones" :key="`z_${zone.zone}`">
      <template v-if="zone.total > 0">
        <v-divider
          v-if="zk > 0"
          class="my-3"
          style="border-color: #9c27b0"
        ></v-divider>
        <div class="text-h6 text-center purple--text" v-if="zones.length > 2">
          {{ zone.zone === "Multi" ? "Multi Zone" : `Zone ${zone.zone}` }}
        </div>
        <v-simple-table class="pickup-table">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in zone.items"
                :key="`p_${item.key}`"
                :class="{
                  'info white--text font-weight-bold':
                    filters.pickup_type === item.key &&
                    filters.zone === zone.zone,
                  'd-none': !item.count,
                }"
              >
                <td
                  @click="setPickupType(item.key, zone.zone, zone.items)"
                  class="cursor-pointer"
                >
                  {{ item.display }}
                </td>
                <td>
                  <span
                    class="cursor-link"
                    @click="showOrderDialog(item.key, zone.zone)"
                    >{{ item.count }}</span
                  >
                </td>
                <td>
                  <v-icon
                    v-if="
                      filters.pickup_type === item.key &&
                      filters.zone === zone.zone
                    "
                    class="white--text"
                    >mdi-checkbox-marked</v-icon
                  >
                  <v-icon
                    v-else
                    @click="setPickupType(item.key, zone.zone, zone.items)"
                    class="cursor-pointer"
                    >mdi-checkbox-blank-outline</v-icon
                  >
                </td>
              </tr>
              <tr>
                <td class="text-center">{{ $t("labels.sum") }}</td>
                <td colspan="2">
                  <span
                    class="cursor-link error--text font-weight-medium"
                    @click="showOrderDialog(null, zone.zone)"
                  >
                    <small v-if="is_cutoff">(Cần xử lý)</small>
                    {{ totalCount(zone.items) }} </span
                  >/{{ zone.total }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </div>

    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="filters.tracking_id"
          class="c-input-small"
          :label="$t('labels.tracking')"
          dense
          outlined
          clearable
          hide-details
          @keyup.enter="getOrder"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          :items="deliveryCompanyOptions"
          v-model="filters.id_delivery_company"
          @change="getConfig"
          class="c-input-small"
          :label="$t('labels.delivery_company_all')"
          dense
          outlined
          clearable
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" v-if="scannedOrders.length > 0">
        <v-chip
          class="mr-1 mb-1"
          v-for="od in scannedOrders"
          :key="od.tracking_id"
        >
          {{ od.tracking_id }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" v-if="myWarehouses.length > 1">
        <v-btn color="warning" block @click="backStep">{{
          $t("labels.back")
        }}</v-btn>
      </v-col>
      <v-col :cols="myWarehouses.length > 1 ? 6 : 12">
        <v-btn color="success" block @click="nextStep" :disabled="isDisabled">{{
          $t("labels.next")
        }}</v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="orderDialog" persistent max-width="1366px">
      <v-card>
        <v-card-title class="mb-3">
          <span class="text-h6">{{ $t("labels.detail") }}</span>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideOrderDialog">
            {{ $t("labels.close") }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th class="text-center">{{ $t("labels.tracking") }}</th>
                  <th class="text-center">
                    {{ $t("labels.customer_order_id") }}
                  </th>
                  <th class="text-center">
                    {{ $t("labels.delivery_company") }}
                  </th>
                  <th class="text-center">
                    {{ $t("labels.delivery_order_id") }}
                  </th>
                  <th class="text-center">
                    {{ $t("labels.order_status_40") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in orders" :key="order.id">
                  <td class="text-center">
                    <OrderTracking :tracking-id="order.tracking_id" />
                  </td>
                  <td class="text-center">{{ order.customer_order_id }}</td>
                  <td class="text-center">{{ order.delivery_company }}</td>
                  <td class="text-center">{{ order.delivery_order_id }}</td>
                  <td class="text-center">
                    {{ formatDateTime(order.receipt_at) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import { SOURCES } from "@/libs/const";

export default {
  components: {
    OrderTracking: () => import("@/components/common/OrderTracking"),
  },
  name: "PickupStep2",
  props: {
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    orders: [],
    orderDialog: false,
    filters: {},
    sourceOptions: [...SOURCES],
    deliveryCompanyOptions: [],
    customerOptions: [],
    scannedOrders: [],
    zones: [],
    is_cutoff: false,
    qrScanDialog: false,
    qrScanType: null,
  }),
  created() {
    this.filters = { ...this.selected };
  },
  computed: {
    activeZones() {
      return [...this.zones].filter((zone) => zone.total > 0);
    },
    isDisabled() {
      return (
        !this.filters.pickup_type &&
        this.filters.pickup_type !== 0 &&
        this.scannedOrders.length === 0
      );
    },
    orderIds() {
      return [...this.scannedOrders].map((o) => o.id);
    },
    myWarehouses() {
      if (!window.me || !window.me.warehouses) {
        return [];
      }
      return window.me.warehouses;
    },
  },
  mounted() {
    this.getConfig();
    this.getDeliveryCompanies();
    this.$root.$on("onPickupUpdated", this.getConfig);
  },
  beforeDestroy() {
    this.$root.$off("onPickupUpdated", this.getConfig);
  },
  methods: {
    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value;
      this.getOrder();
    },
    totalCount(items) {
      let total = 0;
      if (!items || items.length === 0) {
        return total;
      }
      items.forEach((item) => {
        total += item.count;
      });
      return total;
    },
    getConfig: debounce(function () {
      httpClient
        .post("/order-online-pickup-quantities", { ...this.filters })
        .then(({ data }) => {
          this.zones = [...data.groupByZones];
          this.is_cutoff = data.is_cutoff;
        });
    }, 1000),
    getOrders(key, zone) {
      httpClient
        .post("/order-online-pickup-order-list", {
          ...this.filters,
          pickup_type: key,
          zone,
        })
        .then(({ data }) => {
          this.orders = [...data];
        });
    },
    async getOrder() {
      if (!this.filters.tracking_id) {
        return false;
      }

      const checkOrder = [...this.scannedOrders].find(
        (so) =>
          so.tracking_id.toUpperCase() ===
          this.filters.tracking_id.toUpperCase()
      );
      if (checkOrder) {
        this.filters = { ...this.filters, tracking_id: null };
        this.$vToastify.error("Đơn hàng đã quét");
        this.$root.$emit("playErrorAudio");
        return false;
      }

      if (this.isLoading) {
        this.filters = { ...this.filters, tracking_id: null };
        this.$vToastify.warning(this.$t("messages.loading"));
        this.$root.$emit("playErrorAudio");
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/order-online-pickup-order-by-tracking",
          { ...this.filters }
        );
        this.scannedOrders.push(data);
        this.$root.$emit("playSuccessAudio");
        this.isLoading = false;
        this.filters = { ...this.filters, tracking_id: null };
      } catch (e) {
        this.isLoading = false;
        this.filters = { ...this.filters, tracking_id: null };
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$root.$emit("playErrorAudio");
        this.$vToastify.error(errMsg);
      }
    },
    showOrderDialog(key, zone) {
      this.orderDialog = true;
      this.getOrders(key, zone);
    },
    hideOrderDialog() {
      this.orderDialog = false;
    },
    setPickupType(key, zone, items) {
      const config = items.find((i) => i.key === key);
      if (config.count > 0) {
        this.filters = { ...this.filters, pickup_type: key, zone };
      }
    },
    backStep() {
      this.$emit("onUpdate", {
        ...this.filters,
        id_pos: null,
        pickup_type: null,
        zone: null,
        order_ids: [],
        scannedOrders: [],
      });
    },
    nextStep() {
      let config = {};
      const zone = this.zones.find((z) => z.zone === this.filters.zone);
      if (zone) {
        config = zone.items.find((i) => i.key === this.filters.pickup_type);
      }
      this.$emit("onUpdate", {
        ...this.filters,
        config,
        order_ids: this.orderIds,
        scannedOrders: this.scannedOrders,
      });
    },
    async getDeliveryCompanies() {
      const { data } = await httpClient.post(
        "/get-delivery-company-by-pos-and-order-state",
        {
          state: 40,
          id_pos: this.selected.id_pos,
        }
      );
      this.deliveryCompanyOptions = [...data].map((e) => ({
        value: e.id_delivery_company,
        text: e.delivery_company,
      }));
    },
  },
};
</script>
